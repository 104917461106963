import React from 'react';
import { useTranslation } from 'react-i18next';
import LanguageChooser from './languagechooser.js'
import { Link } from 'react-router-dom'
import { useLocation } from "react-router-dom"
import './menubar.css'


export default function Menubar() {
    const location = useLocation();
    const { t, i18n } = useTranslation();

    function changeLang() {
        i18n.resolvedLanguage === "en" ? i18n.changeLanguage("pt") : i18n.changeLanguage("en")
    }

    return (
        <div className="menubar">
            {/*<div className="titletext">JLeao.com</div> */}

            <div className="language">
                <LanguageChooser clickHandler={() => changeLang()} />
            </div>

            <div className="menuitems">
                <div className="search-catalog">
                    <Link to="/" className={location.pathname === "/" ?
                        "spacer menuitemselected" : "spacer menuitemNOTselected"}>{t('menubar.home')}</Link>

                    <Link to="/about" className={location.pathname === "/about" ?
                        "spacer menuitemselected" : "spacer menuitemNOTselected"}>{t('menubar.about')}</Link>

                    <Link to="/blog" className={location.pathname === "/blog" ?
                        "spacer menuitemselected" : "spacer menuitemNOTselected"}>{t('menubar.blog')}</Link>

                </div>
            </div>
        </div >
    );
}
