import React from "react";
import { useTranslation } from 'react-i18next';
import PageStyle from './PageStyle.js';
import "./CommonPageStyle.css";

function Home(){
    const { t, i18n } = useTranslation();

    return(
    <div>
        <PageStyle>
            <div className='pageClass'>
                <div style={{ paddingTop: '0', color: "white" }}>
                    <div>
                        <h2>{t('home.wellcometo')} &nbsp;&nbsp;&nbsp;<span  className="text-banner"><em>JLEAO.COM.BR</em></span></h2>
                    </div>
                    <span style={{fontSize:"1.35em", fontWeight:"bold"}}>{t('home.acompanyfocusedinsoftwaredevelopmentfortheweb')}</span>
                    <br />
                    <h4><em>{t('home.whatwedo')}</em></h4>
                    <div style={{ textAlign: "left", width: "100%" }}>
                        <ul>
                        <li><span>{t('home.siteorpagedevelopment')}</span></li>
                        <li><span>{t('home.fromthesimplestpagesto')}</span></li>
                        <li><span>{t('home.websystemsconnectedtosensorsanhardware')}</span></li>
                        <li><span>{t('home.comercialandindustrialautomation')}</span></li>
                        <li><span>{t('home.electroniccommercesystems')}</span></li>
                        </ul>
                    </div>
                    <br />
                    <h4><em>{t('home.ourdifference')}</em></h4>
                    <div style={{ textAlign: "left", width: "100%" }}>
                        <ul>
                            <li><span>{t('home.productquality')}</span></li>
                            <li><span>{t('home.opendevelopmentprocess')}</span></li>
                            <li><span>{t('home.competitiveprices')}</span></li>
                            <li><span>{t('home.accessibleaftersalessupport')}</span></li>
                        </ul>
                    </div>
                    <br />
                    <h4><em>{t('home.thetecnologiesweuse')}</em></h4>
                    <div style={{ textAlign: "left", width: "100%" }}>
                    {t('home.themainonesare')}
                        <br />
                        <ul>
                            <li><span>{t('home.controlleddevelopmentprocess')}</span></li>
                            <li><span>{t('home.cicdwith')}</span></li>
                            <li><span><em>Frontend</em>: {t('home.frontend')}</span></li>
                            <li><span><em>Backend</em>: {t('home.javaandspring')}</span></li>
                            <li><span>{t('home.databases')}</span></li>
                            <li><span>{t('home.testdrivemdevelopment')}</span></li>
                        </ul>
                    </div>
                    <br />
                    <h4><em>{t('home.howtocontactus')}</em></h4>
                    <div style={{ textAlign: "center", width: "100%" }}>
                        {t('home.ifyouwanttocontactus')}<br />
                        <span style={{fontFamily:"courrier"}}>contato@jleao.com.br</span><br />
                        {t('home.orsendamessageto')}<br /> <span style={{fontFamily:"courrier"}}>+55(21)99177-4440</span><br />
                        {t('home.orcliconeoftheiconsinthefooter')}
                    </div>

                    <br />
                    <br />
                    <br />
                </div>
            </div>
        </PageStyle>
    </div>
    )
};

export default Home;