import React, {useState} from "react";
import { useTranslation } from 'react-i18next';
import PageStyle from './PageStyle.js';
import './Blog.css'

function Blog(){
    const { t, i18n } = useTranslation();
    const [isHover, setIsHover] = useState(false);

    const handleMouseEnter = () => {
       setIsHover(true);
    };
    const handleMouseLeave = () => {
       setIsHover(false);
    };
 

    return(
        <div>
        <PageStyle>
            <div className='pageClass cardClassBase'
                 onMouseEnter={handleMouseEnter}
                 onMouseLeave={handleMouseLeave}>
                <p style={{textAlign: 'center'}}>{t('blog.mytechnicalblog')}</p>
                <p style={{textAlign: 'left', fontSize:'0.8em', paddingLeft:'20px'}}>
                    {t('blog.inthisblogiamgoingtopostarticlesofmineormycolaboratorsaswellaslinkstomaterialpublishedelsewhere')}
                </p>
                <div    className='cardClass'
                        style={{width:'94%',height:'100%',border:'1px solid lightgray',padding:'0',textAlign:"left",
                        margin:'auto', fontSize:'0.7em', boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
                    <div style={{width:"100%",height:"250px",marginLeft:"auto",marginRight:"auto",
                                  backgroundImage:'url("./vscode.react.low.color.almost.gray.png")',
                                  backgroundSize:'auto',textAlign:'center',position:'relative' }}>
                        {/*<img src="Search.as.2022-11-15.png" alt="" style={{width:'100%',zIndex:'-10'}}/>*/}
                        <span style={{
                                    position:'relative', 
                                    top:'1em',
                                    width:'100%',
                                    fontStyle:'italic',
                                    fontSize:"50pt",
                                    fontWeight:"bolder",
                                    color:"yellow"}}>LibPDF</span>
                    </div>
                    <div>
                    <p style={{fontFamily:'Amaranth', fontSize:'1.3em',fontWeight:'lighter', textAlign:'left', padding: '10px', overflowX:'auto'}}>
                        LibPDF is a React/Spring demonstration App I am currently developing. A short functional description
                        and the unfinished code are already available
                        at <a href="https://github.com/jorgeleao/LibPDF" 
                        style={{color:'red', fontWeight:'bold'}} target="_blank" rel="noreferrer noopener">
                        https://github.com/jorgeleao/LibPDF</a>.<br/>
                        A partially working, first prototype of the frontend can be accessed
                        <a href="https://www.jleao.com/LibPDF/" 
                        style={{color:'red', fontWeight:'bold'}} target="_blank" rel="noreferrer noopener"> here</a>.<br/>
                        A very limited, provisional Rest-API (backend) was implemented with Node.js/Express.<br/>
                        I also intend to post a more complete description of the code and a 
                        <a href='https://www.youtube.com/watch?v=aeIjOIm8qS0&list=PL7IDy8ckZZPJu0SVrp0Y0ZVzUWQ-k8Iz4&index=1' style={{color:'red', fontWeight:'bold'}} target="_blank" rel="noreferrer noopener"> video</a> on youtube.
                    </p>
                    </div>
                </div>
                <br />
                <br />
                <br />
            </div>
        </PageStyle>
        </div>
    )    
}

export default Blog;