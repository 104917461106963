import React, { useState } from 'react'
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './i18n.js';
import Blog from './Blog.js'
import SlidingMenu from './slidingmenu.js'
import Footer from "./Footer.js";
//import { useLocation } from "react-router-dom"
//import { useTranslation } from 'react-i18next';

import Menubar from './menubar.js'
import Home from './Home.js'
import About from './about.js'
import Projects from './Projects.js'
import ScrollToTop from "./ScrollToTop";

function App() {
  const [activeDiv, setActiveDiv] = useState('slidingmenuhide')
  //const location = useLocation();

	const toggleSlidingMenu = () => {
    setActiveDiv(activeDiv==="slidingmenushow" ? "slidingmenuhide" : "slidingmenushow")
	};



return (
    <div className="App">

        <div className="titleandbars">
            <div onClick={ toggleSlidingMenu } className="appthreebars">&equiv;</div>
            <div className="apptitle">JLeao.com</div>
        </div>

        
        <BrowserRouter  basename={'/'}>
          <ScrollToTop />
          <SlidingMenu activeDiv={activeDiv} toggleSlidingMenu={toggleSlidingMenu} />
          <Menubar />
          <Routes>
            <Route exact path="/" element={<Home />}>
            </Route>
            <Route path="/about" element={<About />}>
            </Route>
            <Route path="/projects" element={<Projects />}>
            </Route>
            <Route path="/blog" element={<Blog />}>
            </Route>
          </Routes>
        </BrowserRouter>
        <Footer />
    </div>
  );
}

export default App;
