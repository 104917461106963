import styled from "styled-components";

const PageStyle = styled.header`
  .pageClass {
    width: 600px;
    position:relative;
    top:0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding: 30px 1em 250px 1em;
/*    box-shadow: 5px 5px 5px 5px #dddddd, -5px 5px 5px 5px #dddddd;*/
/*    background-color: white;*/
  }
  .text-banner{
    font-style: italic; 
    font-size:1.2em;
    text-align: center; 
    -webkit-text-stroke-width: 2px; 
    -webkit-text-stroke-color: white; /*rgb(212, 179, 179);*/
    color: transparent;
  }

  @media only screen and (max-width: 750px) {
    .pageClass {
      width: 90%;
      padding: 30px 1em 250px 1em;
      /*      padding: 100px 60px 60px 60px;  */
    }
  }
`;

export default PageStyle;