import React from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import PageStyle from './PageStyle.js';
import "./CommonPageStyle.css";
//import { t } from "i18next";

function About(){
    const { t, i18n } = useTranslation();

    return(
    <div>
        <PageStyle>
            <div className='pageClass' style={{ paddingTop: '55px', backgroundColor: "white", fontSize:'1em' }}>
                <span id="top">&nbsp;</span>
                {t('about.aboutme')}...
                <div>
                    <div style={{
                        width: '120px',
                        height: '120px',
                        border: '1px solid black',
                        borderRadius: '60px',
                        float: 'left',
                        margin: '15px',
                        marginTop:'50px',
                        overflow: 'hidden'
                    }}>
                        <img src="leao44.jpg" height="120px" alt="" />
                    </div>
                    <p>{t('about.mynameis')}
                        <span style={{ fontFamily: 'times-new-roman', fontSize: '1.2em', fontWeight: 'bold', fontStyle: 'italic' }}> Jorge Lopes de Souza Leão</span>.
                    </p>
                    <p>{t('about.igraduated')} <em>Docteur Ingénieur en Commande des Systèmes et Automatique</em> {t('about.by')} 
                        <em>Université Paul-Sabatier de Toulouse (III)</em>, {t('about.france')}.
                    </p>
                    <p>{t('about.portuguese')}</p>

                    <p>{t('about.recently')} <b>Intelie</b> (<i>http://www.intelie.com.br</i>, a Viasat company) 
                        {t('about.asa')} <em>Software Developer Specialist</em>, {t('about.fromjune')}
                    </p>
                    <p>{t('about.beforethat')}{t('about.alistof')} &nbsp;
                    <Link  to='/projects'
                        style={{
                            color: 'red',
                            fontWeight: 'fontWeight:"bold", paddingLeft:"20px", paddingRight:"20px"',
                            fontSize: '1.1em'
                        }}
                        >{t('about.here')}</Link>&nbsp;.</p>
                    
                    <p>{t('about.iretired')}</p>
                    {t('about.threelinesofaction')}
                    <div style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: '90%',
                        textAlign: 'left'
                    }}>
                        <ul style={{ margin: '0' }}>
                            <li>{t('about.softwaredevelopment')}</li>
                            <li>{t('about.consulting')}</li>
                            <li>{t('about.teaching')}</li>
                        </ul>
                    </div>
                    
                    
                    <p>{t('about.pleasebewellcome')}</p>
                </div>
            </div>
        </PageStyle>
    </div>
    )
}

export default About;
