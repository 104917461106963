import React from 'react'
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import LanguageChooser from './languagechooser.js'

import './slidingmenu.css'
import './i18n.js'

export default function SlidingMenu(props){
  let navigate = useNavigate();

  const handleSlidingClick = (route) => {
    navigate("/"+route)
    props.toggleSlidingMenu()
  }

  const { t, i18n } = useTranslation();
  function changeLang() {
    i18n.resolvedLanguage === "en" ? i18n.changeLanguage("pt") : i18n.changeLanguage("en")
  }

  const boxStyle = {border:'1px solid white', margin:'4px'};

  
  return(
    <div className={`slidingmenu ${props.activeDiv}`} >
      <div className="titleandbars">  
          <div className="apptitle">JLeao.com</div>
          <div className="slidingmenuthreebars" onClick={ () => props.toggleSlidingMenu() }>&equiv;</div>
      </div>
      <div style={{ textAlign: 'center', fontSize:'1.5em' }}>
          <div onClick={() => handleSlidingClick('')} style={boxStyle}>Home</div>
          <div onClick={() => handleSlidingClick('about')} style={boxStyle}>About</div>
          <div onClick={() => handleSlidingClick('blog')} style={boxStyle}>Blog</div>
          <div className="slidingmenu_language">
            <LanguageChooser clickHandler={ () => {changeLang();props.toggleSlidingMenu();}} />
          </div>
        </div>
    </div>
  )
}