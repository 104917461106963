import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'pt',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
            menubar: {
              languagechooser: "...em Português, por favor!",
              home:"Home",
              about:"About",
              blog:"Blog",
              logout:"Logout" 
            },
            home: {
              wellcometo: "Welcome to",
              acompanyfocusedinsoftwaredevelopmentfortheweb:"A company focused in software development for the web",
              whatwedo: "WHAT WE DO",
              siteorpagedevelopment:"Site or page development.", 
              fromthesimplestpagesto:"From the simplest pages to complex online systems with databases.",
              websystemsconnectedtosensorsanhardware:"Development of web systems connected to sensors and hardware.",
              comercialandindustrialautomation:"Development of comercial and industrial automation systems.",
              ourdifference:"OUR DIFFERENCE",
              productquality:"Product quality.",
              opendevelopmentprocess:"Open development process. May be followed by the client on a daily basis.",
              competitiveprices:"Very competitive pricing.",
              accessibleaftersalessupport:"Accessible after sales support.",
              thetecnologiesweuse:"THE TECHNOLOGIES WE USE",
              themainonesare:"The main ones are:",
              controlleddevelopmentprocess:"Controlled development process: Github or Gitlab.",
              cicdwith:"CI/CD with deploy to conventional providers (DigitalOcen droplets), Docker or AWS.",
              javaandspring:"Java and Spring, Tomcat or Jetty, Node and Express.",
              databases:"Databases: PostgreSQL, MySQL/MariaDB, MongoDB.",
              frontend:"React, HTML5/CSS3/Javascript or Typescript.",
              testdrivemdevelopment:"Test driven development (TDD).",
              howtocontactus:"TO CONTACT US",
              ifyouwanttocontactus:"If you want to contact us, send an email to:",
              orsendamessageto:"or send a message to WhatsApp",
              orcliconeoftheiconsinthefooter:"or click one of the icons in the footer.",
              electroniccommercesystems:"E-commerce systems."
            },
            about: {
              aboutme:"About me",
              mynameis:"My name is",
              igraduated:"I graduated in Electronics Engineering from POLI/UFRJ, completed an M.Sc. in Electrical Engineering from COPPE/UFRJ and a",
              by:" from ",
              france:"France",
              portuguese:"Portuguese is my native language, in which I have excelent writing, but I am fluent in English and French too.",
              threelinesofaction:"Summing up, I am currently dedicated to three lines of action in the areas of programming, computer science, data science and IoT.",
              softwaredevelopment:"Software development (mostly React in the frontend and Java in the backend),",
              consulting:"Consulting, and",
              teaching:"Teaching",
              recently:"Recently, I worked at",
              asa:" as a",
              fromjune:" from June 2021 to September 2022. My work involved Javascript (for the older parts of the code) and Typescript (for the newer parts) with React, in the frontend. My work also involved using Java 8 with Spring in the backend.",
              beforethat:"Before that, I worked as a professor for many years at a large university in Rio de Janeiro, Brazil (COPPE/UFRJ) and made an academic career, from teaching assistant to associate professor at COPPE/UFRJ, an exclusive dedication job.",
              iretired: "I retired recently and now I work full-time as a Software Engineer and entrepreneur, dedicated to learning, using and teaching Java, Javascript, React, Node, R and Python and other languages used in client-server architectures, web applications, data science and IoT.",
              imadeacareer:"I made an academic career, from teaching assistant to associate professor at COPPE/UFRJ, an exclusive dedication job. ",
              alistof:" A list of selected applied projects I developed while at COPPE/UFRJ is shown",
              here:"here",
              pleasebewellcome:"Please, be welcome to my site."
            },
            projects: {},
            blog: {
              mytechnicalblog:"My technical blog...",
              inthisblogiamgoingtopostarticlesofmineormycolaboratorsaswellaslinkstomaterialpublishedelsewhere:
              "On this blog, I will post articles by myself or my collaborators, as well as links to material published elsewhere. We will not necessarily present these posts in more than one language."
            }
        }
      },
      pt:{
          translation:{
            menubar: {
              languagechooser: "...in English, please!",
              home:"Início",
              about:"Sobre",
              blog:"Blog",
              logout:"Sair" 
            },
            home: {
              wellcometo: "Benvindo à",
              acompanyfocusedinsoftwaredevelopmentfortheweb:"Uma empresa focada em desenvolvimento de software para a Web.",
              whatwedo: "O QUE FAZEMOS", 
              siteorpagedevelopment:"Desenvolvimento de sites e páginas.", 
              fromthesimplestpagesto:"Das páginas mais simples a sistemas online complexos com bases de dados.",
              websystemsconnectedtosensorsanhardware:"Desenvolvimento de sistemas na WEB conectados a sensores e hardware.",
              comercialandindustrialautomation:"Automação comercial e automação industrial.",
              ourdifference:"NOSSO DIFERENCIAL",
              productquality:"Qualidade do produto.",
              opendevelopmentprocess:"Processo de desenvolvimento aberto. Pode ser acompanhado diariamente pelo cliente.",
              competitiveprices:"Preços muito competitivos.",
              accessibleaftersalessupport:"Suporte pós-venda accessível.",
              thetecnologiesweuse:"AS TECNOLOGIAS QUE USAMOS",
              themainonesare:"As principais são:",
              controlleddevelopmentprocess:"Processo de desenvolvimento controlado: Github ou Gitlab.",
              cicdwith:"CI/CD com deploy em servidores convencionais (DigitalOcean droplets), Docker ou AWS.",
              frontend:"React, HTML5/CSS3/Javascript ou Typescript.",
              javaandspring:"Java e Spring, Tomcat ou Jetty, Node e Express.",
              databases:"Base de dados: PostgreSQL, MySQL/MariaDB, MongoDB.",
              testdrivemdevelopment:"Test driven development (TDD).",
              howtocontactus:"COMO NOS CONTACTAR",
              ifyouwanttocontactus:"Se quiser nos contactar, envie um email para",
              orsendamessageto:"ou envie uma mensagem para o WhatsApp",
              orcliconeoftheiconsinthefooter:"ou clique em um dos ícones no rodapé.",
              electroniccommercesystems:"Lojas virtuais (Sistemas de E-commerce)."
            },
            about: {
              aboutme:"Sobre mim",
              mynameis:"Chamo-me",
              igraduated:"Formei-me em Engenharia Eletrônica pela POLI/UFRJ, completei o M.Sc. em Engenharia Elétrica pela COPPE/UFRJ e obtive o título de",
              by:"pela ",
              france:"França",
              portuguese:"Português é a minha língua nativa, na qual tenho uma excelente escrita, mas também sou fluente em Inglês e Francês.",
              threelinesofaction:"Resumindo, atualmente me dedico a três linhas de ação nas áreas de programação, ciência da computação, ciência dos dados e IoT:",
              softwaredevelopment:"Desenvolvimento de software (principalmente React no frontend e Java no backend),",
              consulting:"Consultoria e",
              teaching:"Ensino",
              recently:"Recentemente trabalhei na",
              asa:" como",
              fromjune:" de junho de 2021 a setembro de 2022. Meu trabalho envolvia Javascript (para as partes mais antigas do código) e Typescript (para as partes mais novas) com React no frontend. Meu trabalho também envolvia usar Java 8 com Spring no backend.",
              beforethat:"Antes disso trabalhei como professor por vários anos em uma grande universidade no Rio de Janeiro, Brasil (COPPE / UFRJ) e fiz a carreira de docente no PEE/COPPE/UFRJ, de Auxiliar de Ensino a Professor Associado 4, um trabalho que requeria dedicação exclusiva. ",
              iretired: "Aposentei-me recentemente da UFRJ e agora trabalho 100% como Engenheiro de Software e empreendedor, dedicado a aprender, usar e ensinar Java, Javascript, Node.js, R, Python e outras linguagens usadas em arquiteturas cliente-servidor, aplicações Web, ciência dos dados e IoT (Internet of Things).",
              imadeacareer:"Fiz a carreira de docente no PEE/COPPE/UFRJ, de Auxiliar de Ensino a Professor Associado 4, um trabalho que requeria dedicação exclusiva.",
              alistof:"Uma lista selecionada de projetos aplicados que desenvolvi enquanto estive na COPPE é mostrada",
              here:"aqui",
              pleasebewellcome:"Por favor, seja bem-vindo ao meu site."
            },
            projects: {},
            blog: {
              mytechnicalblog:"Meu blog técnico...",
              inthisblogiamgoingtopostarticlesofmineormycolaboratorsaswellaslinkstomaterialpublishedelsewhere:
              "Neste blog, vou postar artigos meus ou de meus colaboradores, assim como links para materiais publicados em outros veículos. Não ofereceremos necessariamente versões em várias línguas."
            }
          }
      }
    }
  });

export default i18n;
